import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Contact from './pages/Contact';
import AIVoiceAssistants from './pages/AIVoiceAssistants';
import Pricing from './pages/Pricing';

const App = () => {
  return (
    <Router>
      <div className="bg-gradient-to-br from-black to-purple-900 text-white font-sans">
        <Header />
        
        <main className="relative pt-16">
          <Routes>
            <Route 
              path="/" 
              element={
                <div className="relative z-10 space-y-8 md:space-y-12">
                  <section id="home" className="relative">
                    <Home />
                  </section>
                  <section id="about" className="relative">
                    <About />
                  </section>
                  <section id="services" className="relative">
                    <Services />
                  </section>
                  <section id="contact" className="relative">
                    <Contact />
                  </section>
                </div>
              } 
            />
            <Route path="/ai-voice-assistants" element={<AIVoiceAssistants />} />
            <Route path="/pricing" element={<Pricing />} />
            {/* Catch-all route for undefined paths */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
};

export default App;