import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Phone, Clock, Building2, Wrench, Gavel, 
  BarChart3, Home, Flower2, UtensilsCrossed, 
  Headphones, DollarSign, Calculator, SwitchCamera 
} from 'lucide-react';
import ConversationVisualizer from './ConversationVisualizer';
import CostSimulator from '../components/CostSimulator';

// Add scroll-to-top functionality
const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return null;
};

const IndustryCard = ({ icon: Icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="group relative bg-gradient-to-br from-purple-900/50 to-indigo-900/50 
               backdrop-blur-md rounded-xl border border-purple-500/20 overflow-hidden"
  >
    <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-indigo-500/10 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    
    <div className="relative p-6 flex flex-col space-y-4">
      <div className="flex items-center space-x-4">
        <div className="p-3 bg-purple-900/50 rounded-lg border border-purple-500/30">
          <Icon className="w-6 h-6 text-purple-400" />
        </div>
        <h3 className="text-lg font-semibold text-white">{title}</h3>
      </div>
      <p className="text-gray-300 text-sm leading-relaxed">{description}</p>
    </div>
  </motion.div>
);

const ResultCard = ({ title, value, prefix = "AUD", highlight = false }) => (
  <div className={`${highlight ? 'bg-purple-500/20' : 'bg-purple-900/30'} 
                  rounded-xl p-4 transition-all duration-300 hover:scale-105`}>
    <h4 className="text-gray-300 text-sm mb-2">{title}</h4>
    <div className="flex items-baseline space-x-1">
      <span className="text-purple-400 text-sm">{prefix}</span>
      <span className={`${highlight ? 'text-white' : 'text-gray-200'} text-2xl font-bold`}>
        {value}
      </span>
    </div>
  </div>
);

const Input = ({ label, value, onChange, placeholder, prefix = "AUD" }) => (
  <div className="space-y-2">
    <label className="text-sm text-gray-300">{label}</label>
    <div className="relative">
      <div className="absolute left-3 top-1/2 -translate-y-1/2 text-purple-400 text-sm">
        {prefix}
      </div>
      <input
        type="number"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="w-full bg-purple-900/30 border border-purple-500/30 rounded-lg p-3 pl-12
                text-white placeholder-gray-500 focus:outline-none focus:border-purple-500 
                transition-colors"
      />
    </div>
  </div>
);

const AIVoiceAssistants = () => {
  const [calculatorValues, setCalculatorValues] = useState({
    clientValue: '',
    missedCalls: '',
    aiCost: '',
    closeRate: ''
  });

  const [showCostSimulator, setShowCostSimulator] = useState(false);

  const industries = [
    {
      icon: Building2,
      title: "Medical & Dental",
      description: "24/7 patient support and appointment management, allowing staff to focus ona care."
    },
    {
      icon: Wrench,
      title: "Home Services",
      description: "Never miss an urgent call; manage inquiries and bookings seamlessly."
    },
    {
      icon: Gavel,
      title: "Law Firms",
      description: "Handle client calls and consultations efficiently with AI-powered receptionists."
    },
    {
      icon: BarChart3,
      title: "Sales & Marketing",
      description: "Automate lead qualification and follow-ups, ensuring no client is missed."
    },
    {
      icon: Home,
      title: "Real Estate",
      description: "Automate tenant inquiries and maintenance requests, freeing your team to focus on growth."
    },
    {
      icon: Flower2,
      title: "Landscaping",
      description: "Streamline service requests and appointments with timely AI responses."
    },
    {
      icon: UtensilsCrossed,
      title: "Hospitality",
      description: "Enhance guest experiences by automating reservations and inquiries."
    },
    {
      icon: Headphones,
      title: "Call Centers",
      description: "Manage call volumes with AI, enhancing customer service and handling queries smoothly."
    }
  ];

  const handleInputChange = (field, value) => {
    setCalculatorValues(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-AU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value || 0);
  };

  const calculateResults = () => {
    const { clientValue, missedCalls, aiCost, closeRate } = calculatorValues;
    const numClientValue = parseFloat(clientValue) || 0;
    const numMissedCalls = parseFloat(missedCalls) || 0;
    const numAiCost = parseFloat(aiCost) || 0;
    const numCloseRate = parseFloat(closeRate) || 0;

    const moneyLeft = numClientValue * numMissedCalls;
    const potentialClosures = numMissedCalls * (numCloseRate / 100);
    const monthlyProfit = (potentialClosures * numClientValue) - numAiCost;
    const annualProfit = monthlyProfit * 12;
    const roi = monthlyProfit ? ((monthlyProfit / numAiCost) * 100).toFixed(1) : 0;

    return {
      moneyLeft: formatCurrency(moneyLeft),
      monthlyProfit: formatCurrency(monthlyProfit),
      annualProfit: formatCurrency(annualProfit),
      roi
    };
  };

  const results = calculateResults();

  return (
    <div className="min-h-screen relative overflow-hidden py-12 md:py-24">
      <ScrollToTop />
      {/* Background Elements */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(255,255,255,0.05)_1px,transparent_1px)] bg-[length:48px_48px]" />
      
      {/* Glowing Orb Effects */}
      <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500/30 rounded-full blur-[128px] -z-10 animate-pulse" />
      <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-indigo-500/30 rounded-full blur-[128px] -z-10 animate-pulse delay-700" />

      <div className="container mx-auto px-4">
        {/* Hero Section */}
        <div className="max-w-4xl mx-auto text-center mb-16 md:mb-24">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="inline-flex items-center space-x-2 mb-6 bg-purple-900/50 rounded-full px-4 py-2 border border-purple-500/30"
          >
            <Phone className="w-5 h-5 text-purple-400" />
            <span className="text-purple-300">AI Receptionist</span>
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400"
          >
            The AI Receptionist that Never Sleeps
          </motion.h1>

          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="flex flex-wrap justify-center gap-4 mb-8"
          >
            <div className="flex items-center space-x-2">
              <Clock className="w-5 h-5 text-purple-400" />
              <span className="text-gray-300">24/7 Availability</span>
            </div>
            <div className="flex items-center space-x-2">
              <Phone className="w-5 h-5 text-purple-400" />
              <span className="text-gray-300">Keep Your Number</span>
            </div>
            <div className="flex items-center space-x-2">
              <Clock className="w-5 h-5 text-purple-400" />
              <span className="text-gray-300">Quick Setup</span>
            </div>
          </motion.div>

          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="text-lg text-gray-300 mb-8"
          >
            Capture calls and appointments 24/7, outside of business hours.
          </motion.p>

          {/* Conversation Visualizer */}
          <ConversationVisualizer />
        </div>

        {/* Industries Grid */}
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-2xl md:text-3xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400"
        >
          AI Receptionist Built for Your Business
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-24">
          {industries.map((industry, index) => (
            <IndustryCard
              key={index}
              icon={industry.icon}
              title={industry.title}
              description={industry.description}
            />
          ))}
        </div>

            {/* Calculator Section */}
            <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-5xl mx-auto"
            >
            {/* Calculator Toggle */}
            <div className="flex flex-col items-center justify-center space-y-4 mb-8">
                <div className="flex items-center justify-center space-x-3">
                <Calculator className="w-6 h-6 text-purple-400" />
                <h2 className="text-2xl md:text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
                    {showCostSimulator ? 'Cost Simulator' : 'AI Receptionist Value Calculator'}
                </h2>
                </div>
                
                <button 
                type="button"
                onClick={() => {
                    console.log('Button clicked');
                    setShowCostSimulator(prev => !prev);
                }}
                className="relative inline-flex items-center space-x-2 px-6 py-3
                            bg-gradient-to-r from-purple-600/50 to-pink-600/50
                            hover:from-purple-500/50 hover:to-pink-500/50
                            rounded-full border border-purple-500/30
                            transform hover:scale-105 active:scale-95
                            transition-all duration-200 ease-in-out
                            cursor-pointer focus:outline-none focus:ring-2 
                            focus:ring-purple-500/50 focus:ring-offset-2 
                            focus:ring-offset-transparent"
                >
                <SwitchCamera className="w-5 h-5 text-purple-300 group-hover:rotate-180 transition-transform duration-300" />
                <span className="text-white font-medium">
                    Switch to {showCostSimulator ? 'ROI Calculator' : 'Cost Simulator'}
                </span>
                
                {/* Animated background glow */}
                <div className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-600/20 to-pink-600/20 
                                blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                </button>
            </div>

            <AnimatePresence mode="wait">
                {showCostSimulator ? (
                <motion.div
                    key="cost-simulator"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                >
                    <CostSimulator />
                </motion.div>
                ) : (
                <motion.div
                    key="roi-calculator"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
              >
                <div className="bg-gradient-to-br from-purple-900/50 to-indigo-900/50 backdrop-blur-md 
                            border border-purple-500/20 rounded-xl p-6 md:p-8 shadow-xl">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {/* Input Section */}
                    <div className="space-y-6">
                      <Input
                        label="Average Client Value"
                        value={calculatorValues.clientValue}
                        onChange={(e) => handleInputChange('clientValue', e.target.value)}
                        placeholder="0.00"
                      />
                      
                      <Input
                        label="Missed Calls Per Month"
                        value={calculatorValues.missedCalls}
                        onChange={(e) => handleInputChange('missedCalls', e.target.value)}
                        placeholder="0"
                        prefix="#"
                      />
                      
                      <Input
                        label="Voice AI Cost"
                        value={calculatorValues.aiCost}
                        onChange={(e) => handleInputChange('aiCost', e.target.value)}
                        placeholder="0.00"
                      />
                      
                      <Input
                        label="Average Close Rate"
                        value={calculatorValues.closeRate}
                        onChange={(e) => handleInputChange('closeRate', e.target.value)}
                        placeholder="0"
                        prefix="%"
                      />
                    </div>

                    {/* Results Section */}
                    <div className="space-y-6">
                      <h3 className="text-xl font-bold text-white mb-6 flex items-center space-x-2">
                        <DollarSign className="w-5 h-5 text-purple-400" />
                        <span>Projected Results</span>
                      </h3>
                      
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <ResultCard
                          title="Money Left on Table"
                          value={results.moneyLeft}
                        />
                        <ResultCard
                          title="Potential Profit/Month"
                          value={results.monthlyProfit}
                        />
                        <ResultCard
                          title="Annual Profit"
                          value={results.annualProfit}
                          highlight={true}
                        />
                        <ResultCard
                          title="ROI"
                          value={results.roi}
                          prefix="%"
                          highlight={true}
                        />
                      </div>

                      <div className="mt-6 p-4 bg-purple-900/30 rounded-lg border border-purple-500/20">
                        <p className="text-sm text-gray-300">
                          These calculations are based on your current input values and represent potential 
                          returns. Actual results may vary based on various factors including market conditions 
                          and business performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default AIVoiceAssistants;