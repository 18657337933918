import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Lightbulb, Shield, Users, Award, Cpu, Target, 
  Workflow, Hexagon, Brain, Code, Rocket, Share2 
} from 'lucide-react';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="relative group"
  >
    <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-indigo-500/20 rounded-xl blur-xl 
                    group-hover:from-purple-500/30 group-hover:to-indigo-500/30 transition-all duration-300" />
    <div className="relative bg-gradient-to-br from-purple-900/50 to-indigo-900/50 backdrop-blur-md p-6 
                    rounded-xl border border-purple-500/20 h-full overflow-hidden">
      {/* Decorative hexagon pattern */}
      <div className="absolute -right-4 -bottom-4 text-purple-500/10">
        <Hexagon size={100} />
      </div>
      
      <div className="relative z-10">
        <div className="mb-4">
          <div className="w-12 h-12 bg-purple-900/70 rounded-lg border border-purple-500/30 
                        flex items-center justify-center mb-2 group-hover:scale-110 transition-transform duration-300">
            <Icon className="w-6 h-6 text-purple-400" />
          </div>
          <h3 className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
            {title}
          </h3>
        </div>
        <p className="text-gray-300 text-sm leading-relaxed">{description}</p>
      </div>
    </div>
  </motion.div>
);

const About = () => {
  const features = [
    {
      icon: Brain,
      title: "AI Innovation",
      description: "Pioneering cutting-edge AI solutions that transform how businesses operate and grow in the digital age."
    },
    {
      icon: Code,
      title: "Tech Excellence",
      description: "Building robust, scalable software solutions with state-of-the-art technologies and best practices."
    },
    {
      icon: Rocket,
      title: "Future-Ready",
      description: "Preparing businesses for tomorrow's challenges with innovative solutions and strategic foresight."
    },
    {
      icon: Shield,
      title: "Reliable Security",
      description: "Implementing advanced security measures to protect your data and ensure business continuity."
    },
    {
      icon: Share2,
      title: "Global Reach",
      description: "Connecting businesses worldwide with intelligent solutions that break geographical barriers."
    },
    {
      icon: Users,
      title: "Expert Team",
      description: "A diverse team of experts committed to delivering excellence in every project we undertake."
    }
  ];

  return (
    <div className="relative py-12 md:py-24 overflow-hidden">
      {/* Animated Background Grid */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(255,255,255,0.05)_1px,transparent_1px)] 
                      bg-[length:48px_48px] [mask-image:radial-gradient(ellipse_80%_80%_at_50%_50%,black,transparent)]" />
      
      {/* Glowing Orb Effects */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-purple-500/30 rounded-full blur-[128px] -z-10 animate-pulse" />
      <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-indigo-500/30 rounded-full blur-[128px] -z-10 animate-pulse delay-700" />

      <div className="container mx-auto px-4">
        {/* Hero Section */}
        <div className="max-w-4xl mx-auto text-center mb-16 md:mb-24">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="inline-flex items-center space-x-2 mb-6 bg-purple-900/50 rounded-full px-4 py-2 border border-purple-500/30"
          >
            <Cpu className="w-5 h-5 text-purple-400" />
            <span className="text-purple-300">Innovation Redefined</span>
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6 bg-clip-text text-transparent 
                       bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400"
          >
            Shaping Tomorrow's Technology
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto"
          >
            TUGADOT is a pioneering force in AI integration and custom software development, 
            dedicated to transforming visionary ideas into powerful technological solutions.
          </motion.p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <FeatureCard {...feature} />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;