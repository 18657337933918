import React, { useState, useEffect, useRef } from 'react';
import { Phone, User2, Bot, Play, Pause } from 'lucide-react';

const ParticleFlow = ({ isPlaying }) => {
  const canvasRef = useRef(null);
  const particles = useRef([]);
  const animationRef = useRef(null);

  const createParticle = () => ({
    x: Math.random() * 0.4 + 0.3,
    y: 0.5,
    size: Math.random() * 2 + 1,
    speedX: (Math.random() - 0.5) * 0.02,
    speedY: (Math.random() - 0.5) * 0.02,
    life: 1,
    direction: Math.random() < 0.5 ? -1 : 1,
    hue: Math.random() < 0.5 ? 280 : 330,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      const { width, height } = canvas.getBoundingClientRect();
      canvas.width = width * window.devicePixelRatio;
      canvas.height = height * window.devicePixelRatio;
      ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const animate = () => {
      const { width, height } = canvas.getBoundingClientRect();
      ctx.clearRect(0, 0, width, height);

      if (isPlaying && Math.random() < 0.3) {
        particles.current.push(createParticle());
      }

      particles.current = particles.current.filter(particle => {
        particle.x += particle.speedX * particle.direction;
        particle.y += particle.speedY;
        particle.life -= 0.01;

        ctx.beginPath();
        ctx.arc(
          particle.x * width,
          particle.y * height,
          particle.size,
          0,
          Math.PI * 2
        );
        ctx.fillStyle = `hsla(${particle.hue}, 70%, 70%, ${particle.life})`;
        ctx.fill();

        return particle.life > 0;
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [isPlaying]);

  return (
    <canvas
      ref={canvasRef}
      className="absolute inset-0 w-full h-full"
      style={{ opacity: 0.7 }}
    />
  );
};

const CustomAudioPlayer = ({ isPlaying, onPlayPause }) => {
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', () => {
        setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
      });
      audioRef.current.addEventListener('loadedmetadata', () => {
        setDuration(audioRef.current.duration);
      });
    }
  }, []);

  const handleProgressClick = (e) => {
    const bounds = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const percentage = (x / bounds.width) * 100;
    if (audioRef.current) {
      audioRef.current.currentTime = (percentage / 100) * audioRef.current.duration;
    }
  };

  return (
    <div className="w-full space-y-2">
      <audio
        ref={audioRef}
        onPlay={() => onPlayPause(true)}
        onPause={() => onPlayPause(false)}
        preload="metadata"
      >
        <source src="https://synthflow.ai/wp-content/uploads/2024/12/Healthcare-Receptionist-Demo-Call.mp3" type="audio/mp3" />
      </audio>
      
      <div className="flex items-center space-x-4">
        <button
          onClick={() => {
            if (isPlaying) {
              audioRef.current?.pause();
            } else {
              audioRef.current?.play();
            }
          }}
          className="w-10 h-10 rounded-full bg-purple-500/20 hover:bg-purple-500/30 
                     flex items-center justify-center transition-all duration-300
                     border border-purple-500/30 hover:border-purple-500/50"
        >
          {isPlaying ? (
            <Pause className="w-5 h-5 text-purple-300" />
          ) : (
            <Play className="w-5 h-5 text-purple-300" />
          )}
        </button>
        
        <div className="flex-1 space-y-1">
          <div
            className="h-2 bg-purple-900/40 rounded-full cursor-pointer relative overflow-hidden"
            onClick={handleProgressClick}
          >
            <div
              className="absolute h-full bg-gradient-to-r from-purple-500 to-pink-500 rounded-full"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className="flex justify-between text-xs text-purple-300">
            <span>{formatTime(audioRef.current?.currentTime || 0)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConversationVisualizer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="w-full max-w-2xl mx-auto mb-8">
      <div className="relative rounded-2xl overflow-hidden">
        {/* Background lighting effects */}
        <div className="absolute -top-40 -left-40 w-80 h-80 bg-purple-500/30 rounded-full blur-[100px] animate-pulse" />
        <div className="absolute -bottom-40 -right-40 w-80 h-80 bg-pink-500/30 rounded-full blur-[100px] animate-pulse delay-200" />
        
        {/* Main card */}
        <div className="relative bg-gradient-to-br from-white/[0.05] to-white/[0.01] border border-white/10 
                       backdrop-blur-xl rounded-2xl p-8 shadow-2xl">
          {/* Glassmorphism overlay */}
          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-pink-500/10 pointer-events-none" />
          
          {/* Content */}
          <div className="relative z-10">
            <h3 className="text-center text-xl font-semibold text-white mb-8 bg-clip-text text-transparent 
                          bg-gradient-to-r from-purple-400 to-pink-400">
              AI Voice Assistant Demo
            </h3>
            
            {/* Avatars and Particles Section */}
            <div className="flex items-center justify-between mb-12 relative h-40">
              {/* Human Avatar */}
              <div className="flex flex-col items-center relative z-10 group">
                <div className="relative">
                  {/* Glow effect */}
                  <div className="absolute inset-0 bg-purple-500/50 rounded-full blur-lg transform 
                                group-hover:scale-110 transition-transform duration-300" />
                  <div className="relative w-20 h-20 rounded-full bg-gradient-to-br from-purple-400 to-pink-400 p-0.5 
                                transform group-hover:scale-105 transition-all duration-300">
                    <div className="w-full h-full rounded-full bg-black/50 backdrop-blur-sm flex items-center justify-center 
                                  border-2 border-white/10">
                      <User2 className="w-10 h-10 text-purple-300" />
                    </div>
                  </div>
                </div>
                <span className="mt-3 text-sm font-medium text-purple-200">Caller</span>
              </div>

              {/* Particle Animation */}
              <div className="absolute inset-0">
                <ParticleFlow isPlaying={isPlaying} />
              </div>

              {/* AI Avatar */}
              <div className="flex flex-col items-center relative z-10 group">
                <div className="relative">
                  {/* Glow effect */}
                  <div className="absolute inset-0 bg-pink-500/50 rounded-full blur-lg transform 
                                group-hover:scale-110 transition-transform duration-300" />
                  <div className="relative w-20 h-20 rounded-full bg-gradient-to-br from-pink-400 to-purple-400 p-0.5 
                                transform group-hover:scale-105 transition-all duration-300">
                    <div className="w-full h-full rounded-full bg-black/50 backdrop-blur-sm flex items-center justify-center 
                                  border-2 border-white/10">
                      <Bot className="w-10 h-10 text-pink-300" />
                    </div>
                  </div>
                </div>
                <span className="mt-3 text-sm font-medium text-purple-200">AI Assistant</span>
              </div>
            </div>

            {/* Custom Audio Player */}
            <div className="relative z-10 bg-black/20 backdrop-blur-md rounded-xl p-6 border border-white/10">
              <div className="flex items-center justify-center space-x-2 text-purple-300 mb-4">
                <Phone className="w-4 h-4" />
                <span className="text-sm font-medium">Sample Conversation</span>
              </div>
              <CustomAudioPlayer isPlaying={isPlaying} onPlayPause={setIsPlaying} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationVisualizer;