import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Code, Cpu, Zap } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, index }) => {
  return (
    <motion.div
      className="relative group"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Moving glow effect */}
      <div className={`absolute inset-0 rounded-lg overflow-hidden glow-container-${index}`}>
        {/* Top border */}
        <div className="absolute inset-x-0 top-0 h-[2px] bg-gradient-to-r from-transparent via-purple-500 to-transparent blur-[1px] glow-line-top" />
        
        {/* Right border */}
        <div className="absolute top-0 right-0 h-full w-[2px] bg-gradient-to-t from-transparent via-purple-500 to-transparent blur-[1px] glow-line-right" />
        
        {/* Bottom border */}
        <div className="absolute inset-x-0 bottom-0 h-[2px] bg-gradient-to-r from-transparent via-purple-500 to-transparent blur-[1px] glow-line-bottom" />
        
        {/* Left border */}
        <div className="absolute top-0 left-0 h-full w-[2px] bg-gradient-to-b from-transparent via-purple-500 to-transparent blur-[1px] glow-line-left" />
      </div>
      
      {/* Content container with transparent background */}
      <div className="relative p-4 md:p-6 rounded-lg backdrop-blur-sm">
        <Icon className="text-purple-300 mb-3 md:mb-4" size={32} />
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-white">{title}</h3>
        <p className="text-sm md:text-base text-gray-300">{description}</p>
      </div>
    </motion.div>
  );
};

const Home = () => {
  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: "ZYFWoDQHjCyxQutOokdey",
      domain: "www.chatbase.co"
    };

    const script = document.createElement('script');
    script.src = "https://www.chatbase.co/embed.min.js";
    script.defer = true;
    script.setAttribute('chatbotId', "ZYFWoDQHjCyxQutOokdey");
    script.setAttribute('domain', "www.chatbase.co");

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes glow-top {
        0% { transform: translateX(-100%); opacity: 0; }
        15%, 25% { transform: translateX(0); opacity: 1; }
        40%, 100% { transform: translateX(100%); opacity: 0; }
      }

      @keyframes glow-right {
        0% { opacity: 0; transform: translateY(-100%); }
        45%, 55% { opacity: 1; transform: translateY(0); }
        65%, 100% { opacity: 0; transform: translateY(100%); }
      }

      @keyframes glow-bottom {
        0%, 65% { transform: translateX(100%); opacity: 0; }
        70%, 80% { transform: translateX(0); opacity: 1; }
        90%, 100% { transform: translateX(-100%); opacity: 0; }
      }

      @keyframes glow-left {
        0%, 90% { transform: translateY(100%); opacity: 0; }
        92%, 97% { transform: translateY(0); opacity: 1; }
        100% { transform: translateY(-100%); opacity: 0; }
      }

      /* First card animations */
      .glow-container-0 .glow-line-top {
        animation: glow-top 6s linear infinite;
      }
      .glow-container-0 .glow-line-right {
        animation: glow-right 6s linear infinite;
      }
      .glow-container-0 .glow-line-bottom {
        animation: glow-bottom 6s linear infinite;
      }
      .glow-container-0 .glow-line-left {
        animation: glow-left 6s linear infinite;
      }

      /* Second card animations */
      .glow-container-1 .glow-line-top {
        animation: glow-top 6s linear infinite;
        animation-delay: 2s;
      }
      .glow-container-1 .glow-line-right {
        animation: glow-right 6s linear infinite;
        animation-delay: 2s;
      }
      .glow-container-1 .glow-line-bottom {
        animation: glow-bottom 6s linear infinite;
        animation-delay: 2s;
      }
      .glow-container-1 .glow-line-left {
        animation: glow-left 6s linear infinite;
        animation-delay: 2s;
      }

      /* Third card animations */
      .glow-container-2 .glow-line-top {
        animation: glow-top 6s linear infinite;
        animation-delay: 4s;
      }
      .glow-container-2 .glow-line-right {
        animation: glow-right 6s linear infinite;
        animation-delay: 4s;
      }
      .glow-container-2 .glow-line-bottom {
        animation: glow-bottom 6s linear infinite;
        animation-delay: 4s;
      }
      .glow-container-2 .glow-line-left {
        animation: glow-left 6s linear infinite;
        animation-delay: 4s;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const services = [
    {
      icon: Cpu,
      title: "AI Integration",
      description: "Harness the power of artificial intelligence to transform your business processes."
    },
    {
      icon: Code,
      title: "Custom Software",
      description: "Tailor-made solutions designed to meet your unique business needs."
    },
    {
      icon: Zap,
      title: "Rapid Prototyping",
      description: "Quick idea validation and development to keep you ahead of the competition."
    }
  ];

  return (
    <div className="relative overflow-hidden">
      {/* Background Elements */}
      <div
        className="fixed inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: "url('/worldbackground.png')",
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <div className="fixed inset-0 bg-gradient-to-br from-purple-900/70 to-indigo-900/70 z-0" />

      <div className="relative z-1 container mx-auto px-4 py-8">
        {/* Hero Section */}
        <section className="flex flex-col lg:flex-row items-center justify-between mb-8 gap-6">
          <div className="text-center lg:text-left lg:w-1/2">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-3 md:mb-4 text-white"
            >
              Shaping the Future with AI
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-lg md:text-xl text-gray-300 max-w-2xl mx-auto lg:mx-0"
            >
              Innovative software solutions powered by cutting-edge technology
            </motion.p>
          </div>

          <motion.div 
            initial={{ opacity: 0, scale: 0.8, rotate: -10 }}
            animate={{ opacity: 1, scale: 1, rotate: 0 }}
            transition={{ 
              duration: 0.8,
              delay: 0.3,
              type: "spring",
              stiffness: 100
            }}
            className="lg:w-1/2 relative max-w-[300px] md:max-w-[500px] mx-auto"
          >
            <div className="absolute inset-0 bg-purple-500/30 rounded-full blur-[100px] transform -translate-y-1/4" />
            <motion.img
              src="/Tugadot_Brain.png"
              alt="TUGADOT AI Brain"
              className="relative w-full"
              animate={{
                y: [0, -20, 0],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              style={{
                filter: 'drop-shadow(0 0 30px rgba(168, 85, 247, 0.4))'
              }}
            />
          </motion.div>
        </section>

        {/* Services Section */}
        <section className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
          {services.map((service, index) => (
            <ServiceCard
              key={service.title}
              {...service}
              index={index}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

export default Home;