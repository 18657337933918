import React, { useState, useEffect } from 'react';
import { 
  Calculator, 
  Mic2, 
  Brain, 
  Phone, 
  Database,
  Plus,
  Info
} from 'lucide-react';

const CostSimulator = () => {
  const [minutes, setMinutes] = useState('');
  const [selectedServices, setSelectedServices] = useState({
    voiceEngine: 'elevenlabs',
    llmAgent: 'gpt4o',
    telephony: 'retellTwilio',
    knowledgeBase: true,
    addOns: {
      phoneNumbers: false,
      extraKnowledgeBase: false
    }
  });

  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [costBreakdown, setCostBreakdown] = useState({});

  const pricing = {
    voiceEngine: {
      elevenlabs: { rate: 0.07, name: "Elevenlabs voices" },
      playht: { rate: 0.08, name: "PlayHT / OpenAI voices" }
    },
    llmAgent: {
      gpt4oMini: { rate: 0.006, name: "GPT 4o mini" },
      gpt4o: { rate: 0.05, name: "GPT 4o" },
      gpt4oRealtime: { rate: 0.50, name: "GPT 4o Realtime" },
      gpt4oMiniRealtime: { rate: 0.125, name: "GPT 4o mini Realtime" },
      claude3Haiku: { rate: 0.012, name: "Claude 3 haiku" },
      claude35Haiku: { rate: 0.02, name: "Claude 3.5 haiku" },
      claude35Sonnet: { rate: 0.06, name: "Claude 3.5 sonnet" },
      yourLLM: { rate: 0, name: "Your LLM" }
    },
    telephony: {
      retellTwilio: { rate: 0.015, name: "Retell Twilio" },
      yourTwilio: { rate: 0, name: "Your Twilio" }
    },
    knowledgeBase: { rate: 0.005, name: "Knowledge Base" },
    addOns: {
      phoneNumbers: { rate: 2, name: "Phone Numbers" },
      extraKnowledgeBase: { rate: 8, name: "Extra Knowledge Base" }
    }
  };

  const calculateCosts = () => {
    const numMinutes = parseInt(minutes) || 0;
    const breakdown = {};
    let total = 0;

    // Calculate per-minute services
    if (selectedServices.voiceEngine) {
      const voiceCost = pricing.voiceEngine[selectedServices.voiceEngine].rate * numMinutes;
      breakdown.voiceEngine = {
        name: pricing.voiceEngine[selectedServices.voiceEngine].name,
        cost: voiceCost,
        rate: pricing.voiceEngine[selectedServices.voiceEngine].rate
      };
      total += voiceCost;
    }

    if (selectedServices.llmAgent) {
      const llmCost = pricing.llmAgent[selectedServices.llmAgent].rate * numMinutes;
      breakdown.llmAgent = {
        name: pricing.llmAgent[selectedServices.llmAgent].name,
        cost: llmCost,
        rate: pricing.llmAgent[selectedServices.llmAgent].rate
      };
      total += llmCost;
    }

    if (selectedServices.telephony) {
      const telephonyCost = pricing.telephony[selectedServices.telephony].rate * numMinutes;
      breakdown.telephony = {
        name: pricing.telephony[selectedServices.telephony].name,
        cost: telephonyCost,
        rate: pricing.telephony[selectedServices.telephony].rate
      };
      total += telephonyCost;
    }

    if (selectedServices.knowledgeBase) {
      const kbCost = pricing.knowledgeBase.rate * numMinutes;
      breakdown.knowledgeBase = {
        name: pricing.knowledgeBase.name,
        cost: kbCost,
        rate: pricing.knowledgeBase.rate
      };
      total += kbCost;
    }

    // Add monthly add-ons
    if (selectedServices.addOns.phoneNumbers) {
      breakdown.phoneNumbers = {
        name: pricing.addOns.phoneNumbers.name,
        cost: pricing.addOns.phoneNumbers.rate,
        rate: pricing.addOns.phoneNumbers.rate
      };
      total += pricing.addOns.phoneNumbers.rate;
    }

    if (selectedServices.addOns.extraKnowledgeBase) {
      breakdown.extraKnowledgeBase = {
        name: pricing.addOns.extraKnowledgeBase.name,
        cost: pricing.addOns.extraKnowledgeBase.rate,
        rate: pricing.addOns.extraKnowledgeBase.rate
      };
      total += pricing.addOns.extraKnowledgeBase.rate;
    }

    setCostBreakdown(breakdown);
    setMonthlyTotal(total);
  };

  useEffect(() => {
    calculateCosts();
  }, [minutes, selectedServices]);

  const ServiceSelector = ({ title, options, value, onChange, icon: Icon }) => (
    <div className="space-y-2">
      <div className="flex items-center space-x-2 text-purple-300">
        <Icon className="w-4 h-4" />
        <label className="text-sm font-medium">{title}</label>
      </div>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full bg-purple-900/30 border border-purple-500/30 rounded-lg p-3
                text-white focus:outline-none focus:border-purple-500 transition-colors"
      >
        {Object.entries(options).map(([key, option]) => (
          <option key={key} value={key}>
            {option.name} (${option.rate}/min)
          </option>
        ))}
      </select>
    </div>
  );

  return (
    <div className="relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(255,255,255,0.05)_1px,transparent_1px)] bg-[length:48px_48px]" />
      
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="text-center mb-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent 
                         bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400">
              Cost Simulator
            </h2>
            <p className="text-gray-300">
              Calculate your estimated monthly costs based on usage and selected services
            </p>
          </div>

          {/* Calculator */}
          <div className="bg-gradient-to-br from-purple-900/50 to-indigo-900/50 backdrop-blur-xl 
                         border border-purple-500/20 rounded-xl p-6 md:p-8 space-y-8">
            
            {/* Minutes Input */}
            <div className="space-y-2">
              <label className="text-sm text-purple-300 font-medium">Monthly Minutes</label>
              <input
                type="number"
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
                placeholder="Enter expected monthly minutes"
                className="w-full bg-purple-900/30 border border-purple-500/30 rounded-lg p-3
                          text-white placeholder-gray-500 focus:outline-none focus:border-purple-500"
              />
            </div>

            {/* Service Selectors */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <ServiceSelector
                title="Voice Engine"
                options={pricing.voiceEngine}
                value={selectedServices.voiceEngine}
                onChange={(value) => setSelectedServices(prev => ({...prev, voiceEngine: value}))}
                icon={Mic2}
              />

              <ServiceSelector
                title="LLM Agent"
                options={pricing.llmAgent}
                value={selectedServices.llmAgent}
                onChange={(value) => setSelectedServices(prev => ({...prev, llmAgent: value}))}
                icon={Brain}
              />

              <ServiceSelector
                title="Telephony"
                options={pricing.telephony}
                value={selectedServices.telephony}
                onChange={(value) => setSelectedServices(prev => ({...prev, telephony: value}))}
                icon={Phone}
              />
            </div>

            {/* Toggles */}
            <div className="space-y-4">
              <div className="flex items-center justify-between p-4 bg-purple-900/30 rounded-lg">
                <div className="flex items-center space-x-2">
                  <Database className="w-4 h-4 text-purple-400" />
                  <span className="text-sm text-gray-300">Knowledge Base</span>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedServices.knowledgeBase}
                    onChange={(e) => setSelectedServices(prev => ({
                      ...prev,
                      knowledgeBase: e.target.checked
                    }))}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-purple-900/50 peer-focus:outline-none rounded-full peer 
                                peer-checked:after:translate-x-full after:content-[''] after:absolute 
                                after:top-[2px] after:left-[2px] after:bg-purple-400 after:rounded-full 
                                after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div>
                </label>
              </div>

              {/* Add-ons Section */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-purple-300 flex items-center space-x-2">
                  <Plus className="w-4 h-4" />
                  <span>Add-ons</span>
                </h4>
                <div className="space-y-2">
                  <label className="flex items-center justify-between p-3 bg-purple-900/30 rounded-lg">
                    <span className="text-sm text-gray-300">Phone Numbers ($2/month)</span>
                    <input
                      type="checkbox"
                      checked={selectedServices.addOns.phoneNumbers}
                      onChange={(e) => setSelectedServices(prev => ({
                        ...prev,
                        addOns: {...prev.addOns, phoneNumbers: e.target.checked}
                      }))}
                      className="form-checkbox h-4 w-4 text-purple-500 rounded border-purple-500/30"
                    />
                  </label>
                  <label className="flex items-center justify-between p-3 bg-purple-900/30 rounded-lg">
                    <span className="text-sm text-gray-300">Extra Knowledge Base ($8/month)</span>
                    <input
                      type="checkbox"
                      checked={selectedServices.addOns.extraKnowledgeBase}
                      onChange={(e) => setSelectedServices(prev => ({
                        ...prev,
                        addOns: {...prev.addOns, extraKnowledgeBase: e.target.checked}
                      }))}
                      className="form-checkbox h-4 w-4 text-purple-500 rounded border-purple-500/30"
                    />
                  </label>
                </div>
              </div>
            </div>

            {/* Cost Breakdown */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-white flex items-center space-x-2">
                <Calculator className="w-5 h-5 text-purple-400" />
                <span>Cost Breakdown</span>
              </h3>
              
              <div className="space-y-2">
                {Object.entries(costBreakdown).map(([key, service]) => (
                  <div key={key} className="flex justify-between items-center p-3 bg-purple-900/30 rounded-lg">
                    <div className="space-y-1">
                      <span className="text-sm text-gray-300">{service.name}</span>
                      {service.rate > 0 && (
                        <div className="text-xs text-gray-400">
                          ${service.rate}/min
                        </div>
                      )}
                    </div>
                    <span className="text-white font-medium">${service.cost.toFixed(2)}</span>
                  </div>
                ))}
              </div>

              {/* Total */}
              <div className="flex justify-between items-center p-4 bg-purple-500/20 rounded-lg border border-purple-500/30">
                <span className="text-lg font-semibold text-white">Total Monthly Cost</span>
                <span className="text-2xl font-bold text-white">${monthlyTotal.toFixed(2)}</span>
              </div>
            </div>

            {/* Enterprise Note */}
            <div className="flex items-start space-x-2 p-4 bg-purple-900/30 rounded-lg">
              <Info className="w-5 h-5 text-purple-400 shrink-0 mt-0.5" />
              <p className="text-sm text-gray-300">
                For enterprise customers, we offer volume-based discounts that can reduce costs to as low as $0.05 per minute. 
                Contact us for custom pricing and dedicated support options.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostSimulator;