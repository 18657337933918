import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Cpu, Code, Briefcase, MessageSquare, Phone, Settings, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const ServiceItem = ({ title, description, items, icon: Icon, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <motion.div 
      className="relative"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ 
        opacity: 1, 
        y: 0,
        transition: { duration: 0.8, delay: index * 0.1 }
      }}
      viewport={{ once: true, margin: "-100px" }}
    >
      <div 
        className="group relative h-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Main Background with Gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-purple-900/50 to-indigo-900/50 backdrop-blur-md 
                      border border-purple-500/20 rounded-2xl z-0 transition-colors duration-300
                      group-hover:from-purple-800/50 group-hover:to-indigo-800/50" />
        
        {/* Animated Border Effect */}
        <div className="absolute inset-0 rounded-2xl overflow-hidden">
          <div className="absolute h-[1px] w-full bg-gradient-to-r from-transparent via-purple-500/50 to-transparent 
                        top-0 left-0 animate-line-x" />
          <div className="absolute h-full w-[1px] bg-gradient-to-b from-transparent via-purple-500/50 to-transparent 
                        top-0 right-0 animate-line-y" />
        </div>

        <div className="relative z-10 p-6 md:p-8 h-full flex flex-col">
          {/* Icon Section */}
          <div className="mb-4 md:mb-6 flex items-center space-x-4">
            <div className="relative">
              <div className="absolute inset-0 bg-purple-500/20 rounded-xl blur-md transform group-hover:scale-110 transition-transform duration-300" />
              <div className="relative p-3 md:p-4 bg-purple-900/50 rounded-xl border border-purple-500/30">
                <Icon className="text-purple-400 w-6 h-6 md:w-8 md:h-8 transform group-hover:scale-110 transition-transform duration-300" />
              </div>
            </div>
            <h3 className="text-xl md:text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
              {title}
            </h3>
          </div>

          {/* Description */}
          <p className="text-sm md:text-base text-gray-300 mb-6 leading-relaxed flex-grow">
            {description}
          </p>

          {/* Features List */}
          <ul className="space-y-3 mb-6">
            {items.map((item, i) => (
              <motion.li
                key={i}
                initial={{ opacity: 0, x: -20 }}
                animate={isHovered ? { opacity: 1, x: 0 } : { opacity: 0.7, x: 0 }}
                transition={{ duration: 0.3, delay: i * 0.1 }}
                className="flex items-center text-gray-200 group/item"
              >
                <span className="w-1 h-1 bg-purple-400 rounded-full mr-3 group-hover/item:w-2 transition-all duration-300" />
                <span className="text-sm md:text-base group-hover/item:text-purple-400 transition-colors duration-300">
                  {item}
                </span>
              </motion.li>
            ))}
          </ul>

          {/* More Info Button - Only for AI Voice Assistants */}
          {title === "AI Voice Assistants" && (
            <Link 
              to="/ai-voice-assistants"
              className="group/button flex items-center justify-center space-x-2 w-full py-2 px-4 
                       bg-gradient-to-r from-purple-600/50 to-indigo-600/50 
                       hover:from-purple-500/50 hover:to-indigo-500/50
                       border border-purple-500/30 rounded-lg 
                       transition-all duration-300 transform hover:scale-102
                       focus:outline-none focus:ring-2 focus:ring-purple-500/50
                       cursor-pointer"
            >
              <span className="text-purple-300 group-hover/button:text-white transition-colors">More Info</span>
              <ArrowRight className="w-4 h-4 text-purple-400 group-hover/button:text-white 
                                   transform group-hover/button:translate-x-1 transition-all" />
            </Link>
          )}

          {/* Tech Circuit Decoration */}
          <div className="absolute -right-4 -bottom-4 w-24 h-24 opacity-10 group-hover:opacity-20 transition-opacity duration-300">
            <svg viewBox="0 0 100 100" className="w-full h-full">
              <circle cx="50" cy="50" r="40" stroke="currentColor" strokeWidth="1" fill="none" />
              <path d="M50 10 L50 90 M10 50 L90 50" stroke="currentColor" strokeWidth="1" />
              <circle cx="50" cy="50" r="8" stroke="currentColor" strokeWidth="1" fill="none" />
            </svg>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Services = () => {
  const services = [
    {
      title: "AI-Powered Chatbots",
      description: "Transform your client interactions with our intelligent chatbot solutions that deliver personalized, 24/7 support and drive engagement to new heights.",
      items: [
        "24/7 Customer Inquiry Handling",
        "Smart Product & Service Recommendations",
        "Automated Appointment Booking",
        "Lead Capture & Qualification",
        "Direct Sales Capabilities"
      ],
      icon: MessageSquare,
    },
    {
      title: "AI Voice Assistants",
      description: "Experience the future of communication with our AI voice assistants that deliver human-like interactions while handling all your business communication needs.",
      items: [
        "Human-like Voice Interactions",
        "Professional Call Handling",
        "Sales Outreach Management",
        "Lead Prospecting",
        "24/7 Availability"
      ],
      icon: Phone,
    },
    {
      title: "Workflow Automations",
      description: "Revolutionize your operations with intelligent automation solutions that eliminate bottlenecks and transform manual processes into seamless workflows.",
      items: [
        "Social Media Content Generation",
        "Client & Employee Onboarding",
        "Process Optimization",
        "Manual Task Elimination",
        "Scalable Operations Management"
      ],
      icon: Settings,
    },
    {
      title: "AI Integration",
      description: "Elevate your systems with cutting-edge AI technologies that adapt and grow with your business needs.",
      items: [
        "Machine Learning Models",
        "Natural Language Processing",
        "Computer Vision Solutions",
        "Predictive Analytics"
      ],
      icon: Cpu,
    },
    {
      title: "Custom Software Development",
      description: "Bring your vision to life with bespoke software solutions crafted by our expert developers to perfectly match your business requirements.",
      items: [
        "Web Applications",
        "Mobile Apps (iOS & Android)",
        "Desktop Software",
        "APIs and Integrations"
      ],
      icon: Code,
    },
    {
      title: "Consulting & Strategy",
      description: "Navigate the future with confidence through our expert guidance in technology decisions and digital transformation initiatives.",
      items: [
        "Technology Roadmapping",
        "AI Strategy Development",
        "Digital Transformation Consulting",
        "Tech Stack Optimization"
      ],
      icon: Briefcase,
    }
  ];

  return (
    <div className="relative min-h-screen flex items-center py-16 md:py-24">
      {/* Animated Background Grid */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(255,255,255,0.05)_1px,transparent_1px)] bg-[length:48px_48px]" />
      
      {/* Glowing Orb Effects */}
      <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500/30 rounded-full blur-[128px] -z-10 animate-pulse" />
      <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-indigo-500/30 rounded-full blur-[128px] -z-10 animate-pulse delay-700" />

      <div className="container mx-auto px-4 space-y-12 md:space-y-16">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl md:text-4xl lg:text-5xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400"
        >
          Our Services
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {services.map((service, index) => (
            <ServiceItem 
              key={index} 
              {...service} 
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;