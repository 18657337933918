import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Phone, MessageSquare, Calendar, ArrowUpRight } from 'lucide-react';

const ContactCard = ({ icon: Icon, title, value, link, delay = 0 }) => (
  <motion.a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    className="group relative overflow-hidden w-full"
  >
    <div className="relative bg-gradient-to-br from-purple-900/50 to-indigo-900/50 backdrop-blur-sm p-6 md:p-8 rounded-xl border border-purple-500/20
                    transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/25 hover:border-purple-500/40">
      {/* Animated gradient background */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      
      {/* Content */}
      <div className="relative flex items-start gap-4">
        <div className="rounded-lg p-3 md:p-4 bg-purple-900/50 border border-purple-500/30">
          <Icon className="w-5 h-5 md:w-6 md:h-6 text-purple-400" />
        </div>
        <div className="flex-1">
          <h3 className="text-base md:text-lg font-semibold text-purple-300 mb-2">{title}</h3>
          <p className="text-sm md:text-base text-gray-300 group-hover:text-white transition-colors duration-300">{value}</p>
        </div>
        <ArrowUpRight className="w-4 h-4 md:w-5 md:h-5 text-purple-400 opacity-0 group-hover:opacity-100 transform translate-x-2 group-hover:translate-x-0 transition-all duration-300" />
      </div>
    </div>
  </motion.a>
);

const Contact = () => {
  return (
    <div className="relative min-h-screen flex items-center py-16 md:py-24">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Animated grid */}
        <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(255,255,255,0.05)_1px,transparent_1px)] bg-[length:48px_48px]" />
        
        {/* Glowing orbs */}
        <div className="absolute top-1/4 left-1/4 w-64 md:w-96 h-64 md:h-96 bg-purple-500/30 rounded-full blur-[128px] animate-pulse" />
        <div className="absolute bottom-1/4 right-1/4 w-64 md:w-96 h-64 md:h-96 bg-indigo-500/30 rounded-full blur-[128px] animate-pulse delay-700" />
      </div>

      <div className="container mx-auto px-4 relative z-10 space-y-12 md:space-y-16">
        {/* Header */}
        <div className="text-center max-w-3xl mx-auto">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400"
          >
            Let's Build Something Amazing Together
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-lg md:text-xl text-gray-300"
          >
            Ready to transform your ideas into reality? Reach out to us through any of these channels.
          </motion.p>
        </div>

        {/* Contact Cards Grid */}
        <div className="grid md:grid-cols-2 gap-6 md:gap-8 max-w-4xl mx-auto">
          <ContactCard
            icon={Mail}
            title="Email Us"
            value="info@tugadot.com"
            link="mailto:info@tugadot.com"
            delay={0.2}
          />
          <ContactCard
            icon={Phone}
            title="Call Us"
            value="+61 484 078 856"
            link="tel:+61484078856"
            delay={0.3}
          />
          <ContactCard
            icon={MessageSquare}
            title="WhatsApp"
            value="Chat with us on WhatsApp"
            link="https://wa.me/61484078856"
            delay={0.4}
          />
          <ContactCard
            icon={Calendar}
            title="Schedule a Call"
            value="Book a free consultation"
            link="https://cal.com/tugadot"
            delay={0.5}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;