import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Check, X, PhoneCall, ArrowRight, Building2, Zap, Star } from 'lucide-react';

const PricingCard = ({ 
  title, 
  description, 
  price, 
  features, 
  highlight = false, 
  extraMinuteCost,
  icon: Icon,
  callVolume
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`relative h-full ${highlight ? 'lg:-mt-4' : ''}`}
    >
      {highlight && (
        <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 bg-gradient-to-r 
                      from-purple-500 to-pink-500 rounded-full text-white text-sm font-medium">
          Most Popular
        </div>
      )}

      <div className={`h-full relative group rounded-2xl p-6 md:p-8
                    backdrop-blur-xl border transition-all duration-300
                    ${highlight 
                      ? 'bg-gradient-to-br from-purple-900/50 to-indigo-900/50 border-purple-500/30 hover:border-purple-500/50'
                      : 'bg-gradient-to-br from-purple-900/30 to-indigo-900/30 border-purple-500/20 hover:border-purple-500/30'}`}>
        
        {/* Header */}
        <div className="mb-6">
          <div className="flex items-center gap-3 mb-3">
            <div className="p-2 rounded-lg bg-purple-900/50 border border-purple-500/30">
              <Icon className="w-5 h-5 text-purple-400" />
            </div>
            <h3 className="text-xl font-bold text-white">{title}</h3>
          </div>
          <p className="text-sm text-gray-300">{description}</p>
        </div>

        {/* Pricing */}
        <div className="mb-6">
          <div className="flex items-baseline gap-1">
            <span className="text-sm text-purple-400">AUD</span>
            <span className="text-4xl font-bold text-white">{price}</span>
            <span className="text-sm text-gray-400">/month</span>
          </div>
          <div className="mt-1 text-sm text-gray-400">
            + AUD {extraMinuteCost}/min after {callVolume} minutes
          </div>
        </div>

        {/* Features */}
        <ul className="space-y-3 mb-8">
          {features.map((feature, index) => (
            <li key={index} className="flex items-start gap-3 text-sm">
              {feature.included ? (
                <Check className="w-5 h-5 text-green-400 shrink-0" />
              ) : (
                <X className="w-5 h-5 text-gray-500 shrink-0" />
              )}
              <span className={feature.included ? 'text-gray-200' : 'text-gray-400'}>
                {feature.text}
              </span>
            </li>
          ))}
        </ul>

        {/* CTA Button */}
        <button className={`w-full py-2 px-4 rounded-lg font-medium transition-all duration-300
                        ${highlight 
                          ? 'bg-gradient-to-r from-purple-500 to-pink-500 text-white hover:from-purple-600 hover:to-pink-600' 
                          : 'bg-purple-900/50 text-purple-300 hover:bg-purple-900/70 border border-purple-500/30'}`}>
          Get Started
        </button>
      </div>
    </motion.div>
  );
};

const Calculator = () => {
  const [minutes, setMinutes] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('startup');

  const plans = {
    startup: { baseMinutes: 1000, baseCost: 199, extraMinuteCost: 0.19 },
    growth: { baseMinutes: 3000, baseCost: 499, extraMinuteCost: 0.17 },
    enterprise: { baseMinutes: 10000, baseCost: 999, extraMinuteCost: 0.15 }
  };

  const calculateCost = () => {
    const numMinutes = parseInt(minutes) || 0;
    const plan = plans[selectedPlan];
    
    if (numMinutes <= plan.baseMinutes) {
      return plan.baseCost;
    }

    const extraMinutes = numMinutes - plan.baseMinutes;
    return plan.baseCost + (extraMinutes * plan.extraMinuteCost);
  };

  return (
    <div className="bg-gradient-to-br from-purple-900/50 to-indigo-900/50 backdrop-blur-xl 
                    border border-purple-500/20 rounded-xl p-6 md:p-8">
      <h3 className="text-xl font-bold text-white mb-6">Cost Calculator</h3>
      
      <div className="space-y-6">
        {/* Plan Selection */}
        <div className="space-y-2">
          <label className="text-sm text-gray-300">Select Plan</label>
          <div className="grid grid-cols-3 gap-2">
            {Object.entries(plans).map(([key, plan]) => (
              <button
                key={key}
                onClick={() => setSelectedPlan(key)}
                className={`py-2 px-4 rounded-lg text-sm font-medium transition-all duration-300
                          ${selectedPlan === key 
                            ? 'bg-purple-500 text-white' 
                            : 'bg-purple-900/50 text-purple-300 hover:bg-purple-900/70'}`}
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Minutes Input */}
        <div className="space-y-2">
          <label className="text-sm text-gray-300">Expected Monthly Minutes</label>
          <div className="relative">
            <input
              type="number"
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
              placeholder="Enter minutes"
              className="w-full bg-purple-900/30 border border-purple-500/30 rounded-lg p-3
                      text-white placeholder-gray-500 focus:outline-none focus:border-purple-500"
            />
          </div>
        </div>

        {/* Results */}
        <div className="p-4 bg-purple-900/30 rounded-lg border border-purple-500/20">
          <div className="flex justify-between items-baseline">
            <span className="text-gray-300">Estimated Monthly Cost:</span>
            <div className="flex items-baseline gap-1">
              <span className="text-sm text-purple-400">AUD</span>
              <span className="text-2xl font-bold text-white">
                {calculateCost().toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        <p className="text-xs text-gray-400">
          Note: This is an estimate. Actual costs may vary based on usage patterns and additional features.
        </p>
      </div>
    </div>
  );
};

const Pricing = () => {
  const commonFeatures = [
    { text: "Keep Your Phone Number", included: true },
    { text: "Voice Cloning", included: true },
    { text: "API Access", included: true },
    { text: "24/7 Support", included: true },
    { text: "Custom Prompts", included: true },
    { text: "Call Analytics", included: true }
  ];

  return (
    <div className="min-h-screen relative overflow-hidden py-12 md:py-24">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(255,255,255,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(255,255,255,0.05)_1px,transparent_1px)] bg-[length:48px_48px]" />
      
      {/* Glowing Orb Effects */}
      <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500/30 rounded-full blur-[128px] -z-10 animate-pulse" />
      <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-indigo-500/30 rounded-full blur-[128px] -z-10 animate-pulse delay-700" />

      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="max-w-2xl mx-auto text-center mb-12 md:mb-16">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400"
          >
            Simple, Transparent Pricing
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="text-lg text-gray-300"
          >
            Choose the perfect plan for your business needs
          </motion.p>
        </div>

        {/* Pricing Cards */}
        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto mb-16">
          <PricingCard
            title="Startup"
            description="Perfect for small businesses just getting started with AI voice assistants."
            price="199"
            extraMinuteCost="0.19"
            callVolume="1,000"
            icon={Zap}
            features={[
              ...commonFeatures,
              { text: "Priority Support", included: false },
              { text: "Custom Voice Training", included: false },
              { text: "Advanced Analytics", included: false }
            ]}
          />
          
          <PricingCard
            title="Growth"
            description="Ideal for growing businesses with moderate call volumes."
            price="499"
            extraMinuteCost="0.17"
            callVolume="3,000"
            icon={Building2}
            highlight={true}
            features={[
              ...commonFeatures,
              { text: "Priority Support", included: true },
              { text: "Custom Voice Training", included: true },
              { text: "Advanced Analytics", included: false }
            ]}
          />
          
          <PricingCard
            title="Enterprise"
            description="For large organizations requiring maximum flexibility and support."
            price="999"
            extraMinuteCost="0.15"
            callVolume="10,000"
            icon={Star}
            features={[
              ...commonFeatures,
              { text: "Priority Support", included: true },
              { text: "Custom Voice Training", included: true },
              { text: "Advanced Analytics", included: true }
            ]}
          />
        </div>

        {/* Calculator Section */}
        <div className="max-w-2xl mx-auto">
          <Calculator />
        </div>
      </div>
    </div>
  );
};

export default Pricing;